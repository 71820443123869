/*http://paletton.com/#uid=23t0u0k++uDIVZC+VMZ+XoJ+siI*/
/* Primary color #00659b*/
html {
    min-height: 100%;
    position: relative;
}

body{
    margin-bottom: 320px;
    font-family: OpenSansRegular;
    font-size:1.5em;
    background-color: #f9f9f9;


}

h1 {

    font-family: OpenSansbold !important;
    font-size: 30px !important;

    line-height: 36px !important;
    color: #003366;
    font-weight: bold;

}
h2 {
    font-family: OpenSans !important;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #003366;
    font-weight: bold;
}

h3 {
    font-family: OpenSansSemibold;
    font-size: 18px;
    line-height: 36px;
    color:#0C3F5B;
}
h4 {
    font-family: OpenSansCondbold;
    font-size: 1.1em;
    line-height: 36px;
    color:#000;
    font-weight: bold;
}

.table-simple{
    width:100%;

    border-collapse: separate;
}

.table-simple>tbody>tr>td{
    padding: 5px;
    vertical-align: baseline !important;
}

.table>tbody>tr>td{
   border-top-color: #326886 !important;
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-bottom-color: #b4d8eb !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    vertical-align: baseline !important;
}
.table > thead > tr > th, .table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tfoot > tr > td {
    border-bottom-width: 1px !important;
    border-bottom-color: #326886 !important;
    border-bottom-style: solid !important;
}

thead{
    border-bottom: 2px solid #FFF;
    color: #FFF;
    font-size: 1em;
    background-color: #00659b;
}

.align-right{
    text-align: right;
}
.align-left{
    text-align: left;
}

.fixcol5{
    width: 5%;
}
.fixcol10{
    width: 10%;
}
.fixcol15{
    width: 15%;
}
.fixcol20{
    width: 20%;
}
.plain-table>tbody>tr>td{
    border-top-color: #326886 !important;
    border-top-width: 0px !important;
    border-top-style: solid !important;
    border-bottom-color: #b4d8eb !important;
    border-bottom-width: 0px;
    border-bottom-style: solid;
    vertical-align: baseline !important;
    padding: 0px !important;

}
th{
    border-top-width: 0px !important;
    border-bottom-style: solid;
    border-bottom-color:  #4e809b;
    border-bottom-width: 1px;
}
.table-simple > tbody>tr:hover {
    background-color: #dfdfdf;
}

.text-medium{
    font-size:1.5em;
}
.text-medium-bold{
    font-size:1.5em;
    font-weight: bold;
}
.text-big{
    font-size: 2em;
}

.text-justify{
    text-align:justify;
}
.margin-top-bottom{
    margin-bottom: 40px;
    margin-top:40px;
}

.padding-top-bottom{
    padding-bottom: 40px;
    padding-top:40px;

}
pre{
    white-space:pre-wrap;
    word-break: keep-all;
    border: 0;
    font-size: 1.1em;
    font-family: OpenSans;
}
.background-alpha{
    background-color: #FFF;
}
.background-darkness{
    background-color: #003e5f;
    color: #ffffff;
    text-align: center;
    z-index: 999;
}

.glyphicon{
    color: #337ab7;

}


.menu-down-glyphicon{
    font-size:18px;
    top: 4px;
}

.info-glyphicon{
    font-size:18px;
    top: 4px;
    left:12px;
}


.glyphicon-question{
    color: #337ab7;
    top: 6px;
    left: 15px;
}

.sortable{
    color: #fca952;
}
.background-changes{
    background-repeat: no-repeat;
    background-size:contain;
    color: #FFF;
    padding: 25px;
    background-image: url('../../public/assets/images/various/changes/backgroundChanges.png');
    min-height:600px;
    min-width: 270px;
    font-weight: bold;
    font-size:18px;
    border-color: rgba(255, 255, 255, .15) !important;
    background-color: #000 !important;
}
