.yesmobile
{display: none}
.margin-left-min{
    margin-left:10px;
}

.margin-top-min{
    margin-top:10px;
}

.nav-pills-help > li > a{
    background-color: #ffffff !important;
    color: #00466c !important;
    border-color:#00466c;
    border-width: 1px;
    border-style: dotted;
}
.nav-pills-faq >li{
    width:100% !important;
}
.nav-pills-faq > li > button{
    background-color: #ffffff !important;
    font-family: OpenSansSemibold;
    text-transform: uppercase;
    color: #00466c !important;
    border-color:#00466c;
    border-width: 1px;
    border-style: dotted;
    position: relative;
    display: block;
    padding: 10px 15px;
    width:100% !important;
}
.nav-pills > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: #001b29;
    color: #FFF;
    font-family: OpenSansSemibold;
       text-transform: uppercase;

}
.nav-pills > li > a {
    background-color: #00466c;
    color: #fff;
    font-family: OpenSansSemibold;
    text-transform: uppercase;
}

.nav-pills > li {
    float:right;
    margin: 5px;
}

.hand{
   cursor: all-scroll; ;
}
.funkyradio div {

    overflow: hidden;
}

.funkyradio label {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #D1D3D4;
    font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
    display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
    position: relative;
    line-height: 2.5em;
    text-indent: 3.25em;
    margin-top: 2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 2.5em;
    background: #D1D3D4;
    border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
    color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
    content: '\2714';
    text-indent: .9em;
    color: #C2C2C2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
    color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
    content: '\2714';
    text-indent: .9em;
    color: #333;
    background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
    box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label:before,
.funkyradio-default input[type="checkbox"]:checked ~ label:before {
    color: #333;
    background-color: #ccc;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label:before,
.funkyradio-success input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #5cb85c;
}

.funkyradio-danger input[type="radio"]:checked ~ label:before,
.funkyradio-danger input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #d9534f;
}

.funkyradio-warning input[type="radio"]:checked ~ label:before,
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #f0ad4e;
}

.funkyradio-info input[type="radio"]:checked ~ label:before,
.funkyradio-info input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #5bc0de;
}


.btn-game-online-player{
    border-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    background-color: #ffffff;
    color: #000;
}
.btn-default, .btn-default:focus{
    border-color: #fea952;
    border-style: solid;
    border-width: 1px;
    background-color: #ffc78d ;
    color: #000;
}
.btn-default:hover{
    border-color: #fea952;
    border-style: solid;
    border-width: 2px;
    background-color: #fea952 !important;
}

.btn-default:hover{

}

.button-toggle{
    background-color: #4d9bcb !important;
    color: #FFF !important;

}



.button-submenu{
    background-color: #16669b;
    color: #FFF ;
    font-size: 1.1em



}

.button-submenu:hover{

    color: #FFF ;
    border-style: solid;
    border-color: #000;
    border-width:1px;
    border-color: #fea952;

    border-width: 2px;
    background-color: #fea952 !important;

}

.btn-cancel, .btn-cancel:focus{
    border-color: rgba(255, 195, 146, 0.37);
    border-style: solid;
    border-width: 1px;
    background-color: rgba(255, 195, 146, 0.37) ;
    color: #000;
}

.btn-cancel:hover{
    border-color: #fea952;
    border-style: solid;
    border-width: 2px;
    background-color: #fea952 !important;
}


.btn-remove, .btn-remove:focus{
    border-color: rgba(255, 195, 146, 0.37);
    border-style: solid;
    border-width: 1px;
    background-color: #d45c45 ;
    color: #ffffff;
}

.btn-used{
    border-color: rgba(176, 176, 176, 0.55);
    border-style: solid;
    border-width: 1px;
    background-color: #ccc ;
    color: #000;
    padding: 5px;
}

.btn-cancel:hover{
    border-color: #fea952;
    border-style: solid;
    border-width: 2px;
    background-color: #fea952 !important;
}

#supporter_save30, #supporter_save90,#supporter_save180, #supporter_save360{

    background-repeat: no-repeat;
    background-size: contain;
    color: #000;
    background-image: url('../../public/assets/images/various/button.png');
    min-height: 51px;
    min-width: 270px;
    font-weight: bold;
    font-size:18px;
    border-color: rgba(255, 255, 255, .15) !important;
    background-color: #f9f9f9 !important;
}


.progress{
    margin-bottom: 0px;
    height: 10px;
}


.submodal{
    position:relative;
}

.item-little{
    margin:0 auto;
    margin-bottom:2%;
    padding:5px;
    max-width: 750px;
    border: 1px solid #000000;
    -webkit-box-shadow: 4px 4px 8px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 8px -4px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 8px -4px rgba(0,0,0,0.75);
}

.separator-subsection{
    background-color: #4d9bcb !important;
    color: #FFF !important;

}

.displaynone{
    display: none;
}

.space{
    margin:10px;
}

#svga-container.svga-boys, #svga-container.svga-girls {
    height:1000px !important;
}

.block-150{
    min-height: 765px !important;
}
#svga-container.svga-dark, .svga-dark .svga-glob-controls,
.svga-dark .svga-col-left .sp-replacer, .svga-dark #svga-footermenu ul li ul li,
.svga-dark #svga-start-overlay, .svga-dark #svga-gender, .svga-dark #svga-dialog,
.svga-dark #svga-gravatar, .svga-dark #svga-message, .svga-dark #svga-gravatar-message,
.svga-dark #svga-ios, .svga-dark #svga-share-block{
    background-color: #093e5f !important;
}


.svga-no-touch.svga-dark .svga-glob-controls:hover, .svga-no-touch.svga-dark .svga-controls:hover,
.svga-dark #svga-bodyzones, .svga-no-touch.svga-dark .svga-blocks:hover, .svga-dark .svga-blocks.svga-active,
.svga-no-touch.svga-dark #svga-footermenu ul li:hover, .svga-dark .scroll-simple_outer > .scroll-element, .svga-dark .svga-input{
    background-color: #16649b !important;
}

.title-superbig{
    font-family: OpenSansBold !important;
    font-size: 3em !important;
    line-height: 62px !important;
    color: #003366;
    font-weight: bold;
}

.title-big{
    font-family: OpenSansBold !important;
    font-size: 35px !important;
    line-height: 36px !important;
    color: #003366;
    font-weight: bold;
}
.subtitle-big{
    font-family: OpenSansbold !important;
    font-size: 30px !important;
    line-height: 36px !important;
    color: #003366;
    font-weight: bold;
}

.text-big-alpha{
    font-family: OpenSansSemibold;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #ffffff;
}

.text-superbig-alpha{
    font-family: OpenSansBold;
    font-size: 40px !important;
    line-height: 36px !important;
    color: #ffffff;
}

.text-medium{
    font-family: OpenSansSemibold;
    font-size: 15px !important;
    line-height: 36px !important;
    color: #000101;

}

.text-little{
    font-size: 12px !important;
}


.text-mini-little{
    font-size: 10px !important;
}

.text-info{
    font-size: 12px;
     font-family: OpenSansSemibold;
    color: #003366;
     font-weight: normal;

}


div.big-line {
    /* cambia estos dos valores para definir el tamaño de tu círculo */
    width:  100%;
    border-radius:100px;
    border:5px solid #fb8f2c;
    margin-bottom:30px;
}

div.big-line2 {
    /* cambia estos dos valores para definir el tamaño de tu círculo */
    width:  100%;
    border-radius:100px;
    border:5px solid #cb3210;
    margin-bottom:30px;
}

.title-search-result{
    font-size:24px;
    font-family: OpenSansCondbold;
    color: #003366;
    font-weight: normal;

    background-repeat: no-repeat;
    background-position:  center;
    min-height:50px;
    text-align: center;
}

.alert-violet {
    color: #733896;
    background-color: #f0e0f9;
    border-color: #9f2ad9;
}

.alert-pink {
    color: #a91f62;
    background-color: #ffcee6;
    border-color: #d92a80;
}

