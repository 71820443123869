#content-line-bottom{
    background-image: url("../../public/assets/images/footer/linefooter.png");
    background-repeat: repeat-x;
    height:4px;
    width:100%;
    float:right;
    position:relative;
    z-index:999;
}

#logo_footer{
    background-image: url("../../public/assets/images/header/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    height:101px;
    top:50px;
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 320px;
    width: 100%;
    text-align: center;
    font-size : 10px;
    background-color: #003e5f;
    color:#FFF;
}

footer a{
    color:#FFF;
    text-decoration: underline;
}

#section_link_footer{
    margin-top:20px;
    margin-left:90px;
    font-size:13px;
}

#slogan_footer{
    color:#FFF;
    top:120px;
    font-size:16px;
    width:300px;
    text-align: left;
}

#redes_sociales_footer{
    text-align: left;
    color:#ff983c;
    font-size: 20px;
    margin-left:25px;
    margin-top: 13px;
    width:350px;
}