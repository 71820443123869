
#header {
    background-color: #000;
    height: 155px;
    width: 100%;
}

#logo{
    background-image: url("../../public/assets/images/header/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    height:101px;
    text-align: center;
    background-position:center;
}

#slogan{
    font-family: StereofidelicRegular;
    color:#FFF;

    font-size: 28px;
    position:relative;
    left:165px;
    top:65px;
}
#slogan_user{
    font-family: StereofidelicRegular;
     color:#FFF;

    font-size: 28px;
    position:relative;
    left:80px;
    top:65px;
}

#header-lineleft-top{
    background-image: url("../../public/assets/images/header/linetopleft.png");
    background-repeat: repeat-x;
    height:4px;
    width:50%;
    float:left;
    position:relative;
    z-index:999;
}

#header-lineright-top{
    background-image: url("../../public/assets/images/header/linetopright.png");
    background-repeat: repeat-x;
    height:4px;
    width:50%;
    float:right;
    position:relative;
    z-index:999;
}
#header-below-line{
    background-image: url("../../public/assets/images/header/line-menu-top.png");
    background-repeat: repeat-x;
    height:4px;
    width:100%;
    float:left;
    position:relative;
    z-index:999;
}


#select-lang {
    margin-top:10px;
    font-family: OpenSansSemibold;
    font-size: 13px;
    margin-right:20%;
    text-transform: uppercase;
    color: #ffffff;
    z-index: 999;
    display:block;
}
#select-lang a{

    font-family: OpenSansSemibold;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    color: #ffffff;
}


.alerts{
    margin-top:4px;
}
.shadow-alert{
    background-color: #fca952;
    display: inline;
    padding: 5px 7px 3px;
    position: relative;
    text-align: center;
    top: -5px;
}
.alert-item{

    display: inline;
    padding: 2px 7px 3px;

}

.badge{
    font-size:16px;
    background-color: rgba(245,121,5,1);
    border-radius: 5px;
    color: #fff !important;

}
.glyphicon-user{
    color: #FFF !important;
}
