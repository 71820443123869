
/* Font */

@font-face{

    font-family: OpenSansCondbold;
    src: url('./tipografia/OpenSans-CondBold.ttf')  format('truetype'), url('./tipografia/OpenSans-CondBold.eot')  format('opentype');
}

@font-face{

    font-family: OpenSansSemibold;
    src: url('./tipografia/OpenSans-Semibold.ttf') format('truetype'), url('./tipografia/OpenSans-Semibold.eot') format('opentype');
}

@font-face{

    font-family: OpenSansbold;
    src: url('./tipografia/OpenSans-Bold.ttf') format('truetype'), url('./tipografia/OpenSans-Bold.eot') format('opentype');
}


@font-face{

    font-family: OpenSansRegular;
    src: url('./tipografia/OpenSans-Regular.ttf') format('truetype'), url('./tipografia/OpenSans-Regular.eot') format('opentype');
}

@font-face{

    font-family: OpenSansSemiboldItalic;
    src: url('./tipografia/OpenSans-SemiboldItalic.ttf') format('truetype'), url('./tipografia/OpenSans-SemiboldItalic.eot') format('opentype');
}

@font-face{

    font-family: OpenSansCondLight;
    src: url('./tipografia/OpenSans-CondLight.ttf') format('truetype'), url('./tipografia/OpenSans-CondLight.eot') format('opentype');
}

@font-face{

    font-family: OpenSansCondLightItalic;
    src: url('./tipografia/OpenSans-CondLightItalic.ttf') format('truetype'), url('./tipografia/OpenSans-CondLightItalic.eot') format('opentype');
}

@font-face{

    font-family: StereofidelicRegular;
    src: url('./tipografia/stereofidelic.ttf') format('truetype'),url('./tipografia/stereofidelic.eot') format('opentype');
}

@font-face{

    font-family: DigitalDisplayTfb;
    src: url('./tipografia/digital-display-tfb.ttf') format('truetype'), url('./tipografia/digital-display-tfb.eot') format('opentype');
}