@media only screen and (max-width: 1200px) {


    h1 {font-size: 18px;}
    #sub_title {width: 200px}
    body {font-size:14px}
    .call_to_action{
        font-size:16px;
        width:150px;
    }

.clock{
    min-width: 70px;
}
    #arrow,
    #content-below-menu-conference,
    #content-below-menu-emblem,
    #content-below-menu-shirt,
    .nomobile
    {display: none !important;}

    .yesmobile
    {display: block !important;}

    #redes_sociales_footer{
        width:150px;
        margin-top:-80px;
        font-size:11px;
        text-align:right;
    }
    #slogan_footer{
        font-size:10px;
        width:150px;
    }
    #welcome{
        display: none;
    }
    .login{
        max-width: 90%;
        min-width: 50%;
    }

    .category-player{

        left: 10px !important;

    }
    .image-back{
        display:none;
    }
    .nomobile{
        display:none;
    }

    .background-trophy{
        background-color: #000;
        background-size:100% 100%;
        background-repeat:no-repeat;
    }

    .background-trophy2{
        background-color: #000;

        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .background-trophy3{
        background-color: #000;
        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .background-trophy-header{
        background-color: #000;
        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .background-trophy-bpccup{
        background-color: #000;
        background-size:100% 100%;
        background-repeat:no-repeat;
    }

    .header-background{
        background: #000 none repeat scroll 0 0;
        width:100%;
        #height: 586px;
        height: 40%;
    }

    .header-image-background {
        background-image: url("../../public/assets/images/home/nologin/playerweb.png");
        background-repeat: no-repeat;
        min-height: 582px;
        background-size: contain;
        background-position:right top;


    }

}
@media only screen and (max-width: 990px) {

    .text-mobile{
        font-size:0.75em!important;
    }
    #summary th{
        min-width: 60px;
    }
    #header{
        background-color: #000 !important;
        height: 330px !important;
        width: 100% !important;

    }
    #slogan {font-size: 18px;}

    .header-background{
        background: #000 none repeat scroll 0 0;
        width:100%;
        #height: 586px;
        height: 40%;
    }

    .header-image-background {
        background-image: url("../../public/assets/images/home/nologin/playerweb.png");
        background-repeat: no-repeat;
        min-height: 582px;
        background-size: contain;
        background-position:right top;


    }

    thead{
        border-bottom: 2px solid #FFF;
        color: #FFF;
        font-size: 0.75em !important;
        background-color: #00659b;
    }
    .number-stat-detail{
        font-size:0.75em !important;
        font-family: OpenSansBold;

    }
    .text-center-mobile{
        text-align: center;
    }
    .separator-item-player{
        border-right: 0px !important;
        padding-left:25px;
    }
    .line-up {
        color: #FF9900;
        border: solid 1px;
    }
    .line-up2{
        color: #cb3210;
        border: solid 1px;
    }
    .little-emblem {
        max-height: 50px !important;
        max-width: 30px !important;
    }
    .pos-user{
        top:-20px;
    }

    .title-mobile{
        font-size: 32px !important;
    }
}


